var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-2 col-sm-2" }, [
    _c(
      "div",
      { staticClass: "list-group" },
      [
        _c(
          "router-link",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.trans("message.compose"),
                expression: "trans('message.compose')"
              }
            ],
            class: [_vm.menu === "compose" ? "active" : "", "list-group-item"],
            attrs: { to: "/message/compose" }
          },
          [
            _c("span", { staticClass: "hidden-sm-up" }, [
              _c("i", { staticClass: "fas fa-paper-plane" })
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "hidden-xs-down" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.trans("message.compose")) +
                  "\n            "
              )
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.trans("message.draft"),
                expression: "trans('message.draft')"
              }
            ],
            class: [_vm.menu === "draft" ? "active" : "", "list-group-item"],
            attrs: { to: "/message/draft" }
          },
          [
            _c("span", { staticClass: "hidden-sm-up" }, [
              _c("i", { staticClass: "fas fa-edit" })
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "hidden-xs-down" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.trans("message.draft")) +
                  "\n                "
              ),
              _vm.statistics.draft
                ? _c("span", [
                    _vm._v(
                      "\n                    (" +
                        _vm._s(_vm.statistics.draft) +
                        ")\n                "
                    )
                  ])
                : _vm._e()
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.trans("message.inbox"),
                expression: "trans('message.inbox')"
              }
            ],
            class: [_vm.menu === "inbox" ? "active" : "", "list-group-item"],
            attrs: { to: "/message/inbox" }
          },
          [
            _c("span", { staticClass: "hidden-sm-up" }, [
              _c("i", { staticClass: "fas fa-inbox" })
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "hidden-xs-down" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.trans("message.inbox")) +
                  "\n                "
              ),
              _vm.statistics.inbox
                ? _c("span", [
                    _vm._v(
                      "\n                    (" +
                        _vm._s(_vm.statistics.inbox) +
                        ")\n                "
                    )
                  ])
                : _vm._e()
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.trans("message.sent_box"),
                expression: "trans('message.sent_box')"
              }
            ],
            class: [_vm.menu === "sent" ? "active" : "", "list-group-item"],
            attrs: { to: "/message/sent" }
          },
          [
            _c("span", { staticClass: "hidden-sm-up" }, [
              _c("i", { staticClass: "far fa-share-square" })
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "hidden-xs-down" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.trans("message.sent_box")) +
                  "\n                "
              ),
              _vm.statistics.sent
                ? _c("span", [
                    _vm._v(
                      "\n                    (" +
                        _vm._s(_vm.statistics.sent) +
                        ")\n                "
                    )
                  ])
                : _vm._e()
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.trans("message.important"),
                expression: "trans('message.important')"
              }
            ],
            class: [
              _vm.menu === "important" ? "active" : "",
              "list-group-item"
            ],
            attrs: { to: "/message/important" }
          },
          [
            _c("span", { staticClass: "hidden-sm-up" }, [
              _c("i", { staticClass: "fas fa-star" })
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "hidden-xs-down" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.trans("message.important")) +
                  "\n            "
              )
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.trans("message.trash"),
                expression: "trans('message.trash')"
              }
            ],
            class: [_vm.menu === "trash" ? "active" : "", "list-group-item"],
            attrs: { to: "/message/trash" }
          },
          [
            _c("span", { staticClass: "hidden-sm-up" }, [
              _c("i", { staticClass: "fas fa-trash" })
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "hidden-xs-down" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.trans("message.trash")) +
                  "\n                "
              ),
              _vm.statistics.trash
                ? _c("span", [
                    _vm._v(
                      "\n                    (" +
                        _vm._s(_vm.statistics.trash) +
                        ")\n                "
                    )
                  ])
                : _vm._e()
            ])
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }