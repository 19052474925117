var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "page-titles p-3 border-bottom" }, [
      _c("h3", { staticClass: "text-themecolor" }, [
        _vm._v(_vm._s(_vm.trans("user.user")) + " \n            "),
        _vm.user.profile
          ? _c("span", { staticClass: "card-subtitle" }, [
              _vm._v(
                _vm._s(
                  _vm.user.profile.first_name + " " + _vm.user.profile.last_name
                ) +
                  " (" +
                  _vm._s(_vm.user.email) +
                  ")"
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-info btn-sm pull-right",
            on: {
              click: function($event) {
                return _vm.$router.push("/user")
              }
            }
          },
          [
            _c("i", { staticClass: "fas fa-list" }),
            _vm._v(" "),
            _c("span", { staticClass: "d-none d-sm-inline" }, [
              _vm._v(_vm._s(_vm.trans("user.user_list")))
            ])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "container-fluid p-0" }, [
      _c(
        "div",
        { staticClass: "row" },
        [
          _c("div", { staticClass: "col-12 col-sm-8" }, [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-body p-4" }, [
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("user-sidebar", {
                      attrs: { menu: "password", id: _vm.id }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-9 col-sm-9" }, [
                      _c(
                        "form",
                        {
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.submit($event)
                            },
                            keydown: function($event) {
                              return _vm.passwordForm.errors.clear(
                                $event.target.name
                              )
                            }
                          }
                        },
                        [
                          _c("h4", { staticClass: "card-title" }, [
                            _vm._v(_vm._s(_vm.trans("auth.change_password")))
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-12" }, [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("label", { attrs: { for: "" } }, [
                                    _vm._v(
                                      _vm._s(_vm.trans("auth.new_password"))
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _vm.getConfig("password_strength_meter")
                                    ? _c(
                                        "div",
                                        [
                                          _c("password", {
                                            attrs: {
                                              name: "new_password",
                                              defaultClass: "form-control",
                                              placeholder: _vm.trans(
                                                "auth.new_password"
                                              ),
                                              required: false
                                            },
                                            model: {
                                              value:
                                                _vm.passwordForm.new_password,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.passwordForm,
                                                  "new_password",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "passwordForm.new_password"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _c("div", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.passwordForm.new_password,
                                              expression:
                                                "passwordForm.new_password"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "password",
                                            name: "new_password",
                                            placeholder: _vm.trans(
                                              "auth.new_password"
                                            )
                                          },
                                          domProps: {
                                            value: _vm.passwordForm.new_password
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.passwordForm,
                                                "new_password",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ]),
                                  _vm._v(" "),
                                  _c("show-error", {
                                    attrs: {
                                      "form-name": _vm.passwordForm,
                                      "prop-name": "new_password"
                                    }
                                  })
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-12" }, [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("label", { attrs: { for: "" } }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.trans(
                                          "auth.new_password_confirmation"
                                        )
                                      )
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.passwordForm
                                            .new_password_confirmation,
                                        expression:
                                          "passwordForm.new_password_confirmation"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "password",
                                      value: "",
                                      name: "new_password_confirmation",
                                      placeholder: _vm.trans(
                                        "auth.new_password_confirmation"
                                      )
                                    },
                                    domProps: {
                                      value:
                                        _vm.passwordForm
                                          .new_password_confirmation
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.passwordForm,
                                          "new_password_confirmation",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("show-error", {
                                    attrs: {
                                      "form-name": _vm.passwordForm,
                                      "prop-name": "new_password_confirmation"
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-info waves-effect waves-light m-t-10 pull-right",
                              attrs: { type: "submit" }
                            },
                            [_vm._v(_vm._s(_vm.trans("auth.change_password")))]
                          )
                        ]
                      )
                    ])
                  ],
                  1
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("user-summary", { attrs: { user: _vm.user } })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }