var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "page-titles p-3 border-bottom" }, [
      _c("h3", { staticClass: "text-themecolor" }, [
        _vm._v(_vm._s(_vm.trans("general.logo")) + "\n            "),
        _c(
          "button",
          {
            staticClass: "btn btn-info btn-sm pull-right",
            on: {
              click: function($event) {
                return _vm.$router.push("/home")
              }
            }
          },
          [
            _c("i", { staticClass: "fas fa-home" }),
            _vm._v(" "),
            _c("span", { staticClass: "d-none d-sm-inline" }, [
              _vm._v(_vm._s(_vm.trans("general.home")))
            ])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "container-fluid p-0" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body p-4" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-12 col-sm-6" },
                  [
                    _c("h4", { staticClass: "card-title" }, [
                      _vm._v(
                        _vm._s(
                          _vm.trans("general.logo_type", {
                            type: _vm.trans("general.sidebar")
                          })
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("upload-image", {
                      attrs: {
                        id: "sidebar_logo",
                        "upload-path": "/configuration/logo/sidebar",
                        "remove-path": "/configuration/logo/sidebar/remove",
                        "image-source": _vm.logo.sidebar
                      },
                      on: {
                        uploaded: _vm.updateSidebarLogo,
                        removed: _vm.updateSidebarLogo
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-12 col-sm-6" },
                  [
                    _c("h4", { staticClass: "card-title" }, [
                      _vm._v(
                        _vm._s(
                          _vm.trans("general.logo_type", {
                            type: _vm.trans("general.main")
                          })
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("upload-image", {
                      attrs: {
                        id: "logo",
                        "upload-path": "/configuration/logo/main",
                        "remove-path": "/configuration/logo/main/remove",
                        "image-source": _vm.logo.main
                      },
                      on: {
                        uploaded: _vm.updateMainLogo,
                        removed: _vm.updateMainLogo
                      }
                    })
                  ],
                  1
                )
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }