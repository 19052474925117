var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "page-titles p-3 border-bottom" }, [
      _c("h3", { staticClass: "text-themecolor" }, [
        _vm._v(_vm._s(_vm.trans("user.user")) + " \n            "),
        _vm.user.profile
          ? _c("span", { staticClass: "card-subtitle" }, [
              _vm._v(
                _vm._s(
                  _vm.user.profile.first_name + " " + _vm.user.profile.last_name
                ) +
                  " (" +
                  _vm._s(_vm.user.email) +
                  ")"
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-info btn-sm pull-right",
            on: {
              click: function($event) {
                return _vm.$router.push("/user")
              }
            }
          },
          [
            _c("i", { staticClass: "fas fa-list" }),
            _vm._v(" "),
            _c("span", { staticClass: "d-none d-sm-inline" }, [
              _vm._v(_vm._s(_vm.trans("user.user_list")))
            ])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "container-fluid p-0" }, [
      _c(
        "div",
        { staticClass: "row" },
        [
          _c("div", { staticClass: "col-12 col-sm-8" }, [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-body p-4" }, [
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("user-sidebar", {
                      attrs: { menu: "email", id: _vm.id }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-9 col-sm-9" }, [
                      _c("h4", { staticClass: "card-title" }, [
                        _vm._v(_vm._s(_vm.trans("user.email")))
                      ]),
                      _vm._v(" "),
                      _c(
                        "form",
                        {
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.submit($event)
                            },
                            keydown: function($event) {
                              return _vm.emailForm.errors.clear(
                                $event.target.name
                              )
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(_vm._s(_vm.trans("template.template")))
                              ]),
                              _vm._v(" "),
                              _c("v-select", {
                                attrs: {
                                  label: "name",
                                  name: "template_id",
                                  id: "template_id",
                                  options: _vm.templates,
                                  placeholder: _vm.trans(
                                    "template.select_template"
                                  )
                                },
                                on: {
                                  select: _vm.getTemplateContent,
                                  close: function($event) {
                                    _vm.emailForm.template_id =
                                      _vm.selected_template.id
                                  },
                                  remove: function($event) {
                                    _vm.emailForm.template_id = ""
                                  }
                                },
                                model: {
                                  value: _vm.selected_template,
                                  callback: function($$v) {
                                    _vm.selected_template = $$v
                                  },
                                  expression: "selected_template"
                                }
                              }),
                              _vm._v(" "),
                              _c("show-error", {
                                attrs: {
                                  "form-name": _vm.emailForm,
                                  "prop-name": "template_id"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(_vm._s(_vm.trans("template.subject")))
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.emailForm.subject,
                                    expression: "emailForm.subject"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  value: "",
                                  name: "subject",
                                  placeholder: _vm.trans("user.subject")
                                },
                                domProps: { value: _vm.emailForm.subject },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.emailForm,
                                      "subject",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("show-error", {
                                attrs: {
                                  "form-name": _vm.emailForm,
                                  "prop-name": "subject"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("html-editor", {
                                attrs: {
                                  name: "body",
                                  model: _vm.emailForm.body,
                                  isUpdate: "true",
                                  "reload-content": _vm.reload_content
                                },
                                on: {
                                  "update:model": function($event) {
                                    return _vm.$set(
                                      _vm.emailForm,
                                      "body",
                                      $event
                                    )
                                  },
                                  clearErrors: function($event) {
                                    return _vm.emailForm.errors.clear("body")
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("show-error", {
                                attrs: {
                                  "form-name": _vm.emailForm,
                                  "prop-name": "body"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-sm btn-info pull-right",
                              attrs: { type: "submit" }
                            },
                            [_vm._v(_vm._s(_vm.trans("message.send")))]
                          )
                        ]
                      )
                    ])
                  ],
                  1
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("user-summary", { attrs: { user: _vm.user } })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }