var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "page-titles p-3 border-bottom" }, [
      _c("h3", { staticClass: "text-themecolor" }, [
        _vm._v(_vm._s(_vm.trans("general.menu")) + "\n            "),
        _c(
          "button",
          {
            staticClass: "btn btn-info btn-sm pull-right",
            on: {
              click: function($event) {
                return _vm.$router.push("/home")
              }
            }
          },
          [
            _c("i", { staticClass: "fas fa-home" }),
            _vm._v(" "),
            _c("span", { staticClass: "d-none d-sm-inline" }, [
              _vm._v(_vm._s(_vm.trans("general.home")))
            ])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "container-fluid p-0" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card-body p-4" },
              [
                _c("show-tip", {
                  attrs: { module: "configuration", tip: "tip_menu" }
                }),
                _vm._v(" "),
                _c("h4", { staticClass: "card-title" }, [
                  _vm._v(_vm._s(_vm.trans("general.menu")))
                ]),
                _vm._v(" "),
                _c(
                  "form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.submit($event)
                      },
                      keydown: function($event) {
                        return _vm.configForm.errors.clear($event.target.name)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "table-responsive" }, [
                      _c("table", { staticClass: "table" }, [
                        _c("tbody", [
                          _c("tr", [
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.trans("configuration.show_menu", {
                                    menu: _vm.trans("user.user")
                                  })
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c("switches", {
                                  attrs: {
                                    theme: "bootstrap",
                                    color: "success"
                                  },
                                  model: {
                                    value: _vm.configForm.show_user_menu,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.configForm,
                                        "show_user_menu",
                                        $$v
                                      )
                                    },
                                    expression: "configForm.show_user_menu"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.trans("configuration.show_menu", {
                                    menu: _vm.trans("todo.todo")
                                  })
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c("switches", {
                                  attrs: {
                                    theme: "bootstrap",
                                    color: "success"
                                  },
                                  model: {
                                    value: _vm.configForm.show_todo_menu,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.configForm,
                                        "show_todo_menu",
                                        $$v
                                      )
                                    },
                                    expression: "configForm.show_todo_menu"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.trans("configuration.show_menu", {
                                    menu: _vm.trans("message.message")
                                  })
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c("switches", {
                                  attrs: {
                                    theme: "bootstrap",
                                    color: "success"
                                  },
                                  model: {
                                    value: _vm.configForm.show_message_menu,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.configForm,
                                        "show_message_menu",
                                        $$v
                                      )
                                    },
                                    expression: "configForm.show_message_menu"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.trans("configuration.show_menu", {
                                    menu: _vm.trans(
                                      "configuration.configuration"
                                    )
                                  })
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c("switches", {
                                  attrs: {
                                    theme: "bootstrap",
                                    color: "success"
                                  },
                                  model: {
                                    value:
                                      _vm.configForm.show_configuration_menu,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.configForm,
                                        "show_configuration_menu",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "configForm.show_configuration_menu"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.trans("configuration.show_menu", {
                                    menu: _vm.trans("backup.backup")
                                  })
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c("switches", {
                                  attrs: {
                                    theme: "bootstrap",
                                    color: "success"
                                  },
                                  model: {
                                    value: _vm.configForm.show_backup_menu,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.configForm,
                                        "show_backup_menu",
                                        $$v
                                      )
                                    },
                                    expression: "configForm.show_backup_menu"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.trans("configuration.show_menu", {
                                    menu: _vm.trans("template.email_template")
                                  })
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c("switches", {
                                  attrs: {
                                    theme: "bootstrap",
                                    color: "success"
                                  },
                                  model: {
                                    value:
                                      _vm.configForm.show_email_template_menu,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.configForm,
                                        "show_email_template_menu",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "configForm.show_email_template_menu"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.trans("configuration.show_menu", {
                                    menu: _vm.trans("mail.email_log")
                                  })
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c("switches", {
                                  attrs: {
                                    theme: "bootstrap",
                                    color: "success"
                                  },
                                  model: {
                                    value: _vm.configForm.show_email_log_menu,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.configForm,
                                        "show_email_log_menu",
                                        $$v
                                      )
                                    },
                                    expression: "configForm.show_email_log_menu"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.trans("configuration.show_menu", {
                                    menu: _vm.trans("activity.activity_log")
                                  })
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c("switches", {
                                  attrs: {
                                    theme: "bootstrap",
                                    color: "success"
                                  },
                                  model: {
                                    value:
                                      _vm.configForm.show_activity_log_menu,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.configForm,
                                        "show_activity_log_menu",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "configForm.show_activity_log_menu"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-info waves-effect waves-light pull-right m-t-10",
                        attrs: { type: "submit" }
                      },
                      [_vm._v(_vm._s(_vm.trans("general.save")))]
                    )
                  ]
                )
              ],
              1
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }