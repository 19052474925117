var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "page-titles p-3 border-bottom" }, [
      _c("h3", { staticClass: "text-themecolor" }, [
        _vm._v(
          _vm._s(_vm.trans("permission.assign_permission")) + "\n            "
        ),
        _c(
          "button",
          {
            staticClass: "btn btn-info btn-sm pull-right",
            on: {
              click: function($event) {
                return _vm.$router.push("/home")
              }
            }
          },
          [
            _c("i", { staticClass: "fas fa-home" }),
            _vm._v(" "),
            _c("span", { staticClass: "d-none d-sm-inline" }, [
              _vm._v(_vm._s(_vm.trans("general.home")))
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-info btn-sm pull-right m-r-10",
            on: {
              click: function($event) {
                return _vm.$router.push("/configuration/permission")
              }
            }
          },
          [
            _c("i", { staticClass: "fas fa-key" }),
            _vm._v(" "),
            _c("span", { staticClass: "d-none d-sm-inline" }, [
              _vm._v(_vm._s(_vm.trans("permission.permission")))
            ])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "container-fluid p-0" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("div", { staticClass: "table-responsive m-b-20" }, [
                  _c("table", { staticClass: "table table-hover" }, [
                    _c("thead", [
                      _c(
                        "tr",
                        [
                          _c("th", [
                            _vm._v(_vm._s(_vm.trans("permission.permission")))
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.roles, function(role) {
                            return _c("th", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(_vm.toWord(role.name)))
                            ])
                          })
                        ],
                        2
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.permissions, function(permission) {
                        return _c(
                          "tr",
                          [
                            _c("td", [
                              _vm._v(_vm._s(_vm.toWord(permission.name)))
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.roles, function(role) {
                              return _c(
                                "td",
                                { staticClass: "text-center" },
                                [
                                  _c("switches", {
                                    attrs: {
                                      theme: "bootstrap",
                                      color: "success"
                                    },
                                    model: {
                                      value:
                                        _vm.assignPermissionForm.data[role.id][
                                          permission.id
                                        ],
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.assignPermissionForm.data[
                                            role.id
                                          ],
                                          permission.id,
                                          $$v
                                        )
                                      },
                                      expression:
                                        "assignPermissionForm.data[role.id][permission.id]"
                                    }
                                  })
                                ],
                                1
                              )
                            })
                          ],
                          2
                        )
                      }),
                      0
                    )
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-sm btn-info waves-effect waves-light m-l-20",
                    attrs: { type: "button" },
                    on: { click: _vm.savePermission }
                  },
                  [_vm._v(_vm._s(_vm.trans("general.save")))]
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "btn btn-sm btn-danger",
                    attrs: { to: "/configuration/permission" }
                  },
                  [_vm._v(_vm._s(_vm.trans("general.back")))]
                )
              ],
              1
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }