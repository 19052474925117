var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "page-titles p-3 border-bottom" }, [
      _c("h3", { staticClass: "text-themecolor" }, [
        _vm._v(
          _vm._s(_vm.trans("configuration.scheduled_job")) + "\n            "
        ),
        _c(
          "button",
          {
            staticClass: "btn btn-info btn-sm pull-right",
            on: {
              click: function($event) {
                return _vm.$router.push("/home")
              }
            }
          },
          [
            _c("i", { staticClass: "fas fa-home" }),
            _vm._v(" "),
            _c("span", { staticClass: "d-none d-sm-inline" }, [
              _vm._v(_vm._s(_vm.trans("general.home")))
            ])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "container-fluid p-0" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card-body p-4" },
              [
                _c("show-tip", {
                  attrs: { module: "configuration", tip: "tip_scheduled_job" }
                }),
                _vm._v(" "),
                _c("p", [_vm._v("Add below cron command in your server:")]),
                _vm._v(" "),
                _c("blockquote", [
                  _vm._v(
                    "\n                            php /path-to-artisan schedule:run >> /dev/null 2>&1\n                        "
                  )
                ])
              ],
              1
            )
          ]),
          _vm._v(" "),
          _vm._m(0)
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card" }, [
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "table-responsive" }, [
          _c("table", { staticClass: "table table-stripped table-hover" }, [
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v("Action")]),
                _vm._v(" "),
                _c("th", [_vm._v("Frequency")])
              ])
            ]),
            _vm._v(" "),
            _c("tbody", [
              _c("tr", [
                _c("td", [
                  _vm._v("Birthday/Anniversary wish to Staff/Customer")
                ]),
                _vm._v(" "),
                _c("td", [_vm._v("Once per day at 09:00 AM")])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("Daily Backup")]),
                _vm._v(" "),
                _c("td", [_vm._v("Once per day at 01:00 AM")])
              ])
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }