var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "page-titles p-3 border-bottom" }, [
      _c("h3", { staticClass: "text-themecolor" }, [
        _vm._v(_vm._s(_vm.trans("message.trash")) + " \n            "),
        _vm.messages
          ? _c("span", { staticClass: "card-subtitle" }, [
              _vm._v(
                _vm._s(
                  _vm.trans("general.total_result_found", {
                    count: _vm.messages.total
                  })
                )
              )
            ])
          : _c("span", { staticClass: "card-subtitle" }, [
              _vm._v(_vm._s(_vm.trans("general.no_result_found")))
            ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-info btn-sm pull-right",
            on: {
              click: function($event) {
                return _vm.$router.push("/message")
              }
            }
          },
          [
            _c("i", { staticClass: "fas fa-envelope" }),
            _vm._v(" "),
            _c("span", { staticClass: "d-none d-sm-inline" }, [
              _vm._v(_vm._s(_vm.trans("message.message")))
            ])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "container-fluid p-0" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body p-4" }, [
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c("message-sidebar", {
                    attrs: { menu: "trash", statistics: _vm.statistics }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-10 col-sm-10" },
                    [
                      _c("div", { staticClass: "table-responsive" }, [
                        _vm.messages.total
                          ? _c("table", { staticClass: "table" }, [
                              _c("thead", [
                                _c("tr", [
                                  _c("th", [
                                    _vm._v(_vm._s(_vm.trans("message.sender")))
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(_vm.trans("message.recipient"))
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(_vm._s(_vm.trans("message.subject")))
                                  ]),
                                  _vm._v(" "),
                                  _c("th"),
                                  _vm._v(" "),
                                  _c("th", [
                                    _vm._v(
                                      _vm._s(_vm.trans("message.date_time"))
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("th", { staticClass: "table-option" }, [
                                    _vm._v(_vm._s(_vm.trans("general.action")))
                                  ])
                                ])
                              ]),
                              _vm._v(" "),
                              _c(
                                "tbody",
                                _vm._l(_vm.messages.data, function(trash) {
                                  return _c("tr", [
                                    _c("td", [
                                      _vm._v(_vm._s(trash.user_from.email))
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(_vm._s(trash.user_to.email))
                                    ]),
                                    _vm._v(" "),
                                    _c("td", {
                                      domProps: {
                                        textContent: _vm._s(trash.subject)
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("td", [
                                      trash.has_attachment
                                        ? _c("i", {
                                            staticClass: "fas fa-paperclip"
                                          })
                                        : _vm._e()
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("momentDateTime")(
                                            trash.created_at
                                          )
                                        )
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("td", { staticClass: "table-option" }, [
                                      _c("div", { staticClass: "btn-group" }, [
                                        _c(
                                          "button",
                                          {
                                            directives: [
                                              {
                                                name: "tooltip",
                                                rawName: "v-tooltip",
                                                value: _vm.trans(
                                                  "message.restore"
                                                ),
                                                expression:
                                                  "trans('message.restore')"
                                              }
                                            ],
                                            staticClass:
                                              "btn btn-success btn-sm",
                                            on: {
                                              click: function($event) {
                                                return _vm.restore(trash)
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fas fa-reply"
                                            })
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            directives: [
                                              {
                                                name: "confirm",
                                                rawName: "v-confirm",
                                                value: {
                                                  ok: _vm.confirmDelete(trash)
                                                },
                                                expression:
                                                  "{ok: confirmDelete(trash)}"
                                              },
                                              {
                                                name: "tooltip",
                                                rawName: "v-tooltip",
                                                value: _vm.trans(
                                                  "message.delete_permanently"
                                                ),
                                                expression:
                                                  "trans('message.delete_permanently')"
                                              }
                                            ],
                                            key: trash.id,
                                            staticClass: "btn btn-danger btn-sm"
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fas fa-trash"
                                            })
                                          ]
                                        )
                                      ])
                                    ])
                                  ])
                                }),
                                0
                              )
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      !_vm.messages.total
                        ? _c("module-info", {
                            attrs: {
                              module: "message",
                              title: "module_info_title_trash",
                              description: "module_info_description_trash",
                              icon: "trash"
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("pagination-record", {
                        attrs: {
                          "page-length": _vm.filterMessageForm.page_length,
                          records: _vm.messages
                        },
                        on: {
                          "update:pageLength": function($event) {
                            return _vm.$set(
                              _vm.filterMessageForm,
                              "page_length",
                              $event
                            )
                          },
                          "update:page-length": function($event) {
                            return _vm.$set(
                              _vm.filterMessageForm,
                              "page_length",
                              $event
                            )
                          },
                          updateRecords: _vm.getMessages
                        },
                        nativeOn: {
                          change: function($event) {
                            return _vm.getMessages($event)
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }