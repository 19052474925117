var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "page-titles p-3 border-bottom" }, [
      _c(
        "h3",
        { staticClass: "text-themecolor" },
        [
          _vm._v(_vm._s(_vm.trans("mail.email_log")) + "\n            "),
          _vm.email_logs
            ? _c("span", { staticClass: "card-subtitle" }, [
                _vm._v(
                  _vm._s(
                    _vm.trans("general.total_result_found", {
                      count: _vm.email_logs.total
                    })
                  )
                )
              ])
            : _c("span", { staticClass: "card-subtitle" }, [
                _vm._v(_vm._s(_vm.trans("general.no_result_found")))
              ]),
          _vm._v(" "),
          _c("sort-by", {
            staticClass: "pull-right",
            attrs: {
              "order-by-options": _vm.orderByOptions,
              "sort-by": _vm.filterEmailLogForm.sort_by,
              order: _vm.filterEmailLogForm.order
            },
            on: {
              updateSortBy: function(value) {
                _vm.filterEmailLogForm.sort_by = value
              },
              updateOrder: function(value) {
                _vm.filterEmailLogForm.order = value
              }
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "container-fluid p-0" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _vm.email_logs.total
                  ? _c("div", { staticClass: "table-responsive" }, [
                      _c("table", { staticClass: "table table-hover" }, [
                        _c("thead", [
                          _c("tr", [
                            _c("th", [
                              _vm._v(_vm._s(_vm.trans("mail.receiver")))
                            ]),
                            _vm._v(" "),
                            _c("th", [
                              _vm._v(_vm._s(_vm.trans("mail.subject")))
                            ]),
                            _vm._v(" "),
                            _c("th", [
                              _vm._v(_vm._s(_vm.trans("mail.sent_at")))
                            ]),
                            _vm._v(" "),
                            _c("th", { staticClass: "table-option" }, [
                              _vm._v(_vm._s(_vm.trans("general.action")))
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "tbody",
                          _vm._l(_vm.email_logs.data, function(email_log) {
                            return _c("tr", [
                              _c("td", {
                                domProps: {
                                  textContent: _vm._s(email_log.to_address)
                                }
                              }),
                              _vm._v(" "),
                              _c("td", {
                                domProps: {
                                  textContent: _vm._s(email_log.subject)
                                }
                              }),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm._f("moment")(email_log.created_at))
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "table-option" }, [
                                _c("div", { staticClass: "btn-group" }, [
                                  _c(
                                    "button",
                                    {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: _vm.trans("mail.view"),
                                          expression: "trans('mail.view')"
                                        }
                                      ],
                                      staticClass: "btn btn-info btn-sm",
                                      attrs: {
                                        type: "button",
                                        "data-toggle": "modal",
                                        "data-target": ".email-log-detail"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.fetchEmailLog(email_log)
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fas fa-arrow-circle-right"
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      directives: [
                                        {
                                          name: "confirm",
                                          rawName: "v-confirm",
                                          value: {
                                            ok: _vm.confirmDelete(email_log)
                                          },
                                          expression:
                                            "{ok: confirmDelete(email_log)}"
                                        },
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: _vm.trans("general.delete"),
                                          expression: "trans('general.delete')"
                                        }
                                      ],
                                      key: email_log.id,
                                      staticClass: "btn btn-danger btn-sm"
                                    },
                                    [_c("i", { staticClass: "fas fa-trash" })]
                                  )
                                ])
                              ])
                            ])
                          }),
                          0
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.email_logs.total
                  ? _c("module-info", {
                      attrs: {
                        module: "mail",
                        title: "module_info_email_log_title",
                        description: "module_info_email_log_description",
                        icon: "envelope"
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("pagination-record", {
                  attrs: {
                    "page-length": _vm.filterEmailLogForm.page_length,
                    records: _vm.email_logs
                  },
                  on: {
                    "update:pageLength": function($event) {
                      return _vm.$set(
                        _vm.filterEmailLogForm,
                        "page_length",
                        $event
                      )
                    },
                    "update:page-length": function($event) {
                      return _vm.$set(
                        _vm.filterEmailLogForm,
                        "page_length",
                        $event
                      )
                    },
                    updateRecords: _vm.getEmailLogs
                  },
                  nativeOn: {
                    change: function($event) {
                      return _vm.getEmailLogs($event)
                    }
                  }
                })
              ],
              1
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal fade email-log-detail",
          staticStyle: { display: "none" },
          attrs: {
            tabindex: "-1",
            role: "dialog",
            "aria-labelledby": "emailLogDetail",
            "aria-hidden": "true"
          }
        },
        [
          _c("div", { staticClass: "modal-dialog modal-lg" }, [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header" }, [
                _c(
                  "h4",
                  {
                    staticClass: "modal-title",
                    attrs: { id: "emailLogDetail" }
                  },
                  [_vm._v(_vm._s(_vm.trans("mail.email")))]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "close",
                    attrs: {
                      type: "button",
                      "data-dismiss": "modal",
                      "aria-hidden": "true"
                    }
                  },
                  [_vm._v("×")]
                )
              ]),
              _vm._v(" "),
              _vm.email_log
                ? _c("div", { staticClass: "modal-body" }, [
                    _c("h4", [
                      _vm._v(
                        _vm._s(_vm.email_log.subject) +
                          "\n                            "
                      ),
                      _c("span", { staticClass: "pull-right" }, [
                        _vm._v(
                          _vm._s(_vm._f("moment")(_vm.email_log.created_at))
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.trans("mail.sender") +
                            ": " +
                            _vm.email_log.from_address
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.trans("mail.receiver") +
                            ": " +
                            _vm.email_log.to_address
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", {
                      domProps: { innerHTML: _vm._s(_vm.email_log.body) }
                    })
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-danger waves-effect text-left",
                    attrs: { type: "button", "data-dismiss": "modal" }
                  },
                  [_vm._v(_vm._s(_vm.trans("general.close")))]
                )
              ])
            ])
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }