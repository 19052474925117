var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "page-titles p-3 border-bottom" }, [
      _c("h3", { staticClass: "text-themecolor" }, [
        _vm._v(_vm._s(_vm.trans("user.user")) + " \n            "),
        _vm.user.profile
          ? _c("span", { staticClass: "card-subtitle" }, [
              _vm._v(
                _vm._s(
                  _vm.user.profile.first_name + " " + _vm.user.profile.last_name
                ) +
                  " (" +
                  _vm._s(_vm.user.email) +
                  ")"
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-info btn-sm pull-right",
            on: {
              click: function($event) {
                return _vm.$router.push("/user")
              }
            }
          },
          [
            _c("i", { staticClass: "fas fa-list" }),
            _vm._v(" "),
            _c("span", { staticClass: "d-none d-sm-inline" }, [
              _vm._v(_vm._s(_vm.trans("user.user_list")))
            ])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "container-fluid p-0" }, [
      _c(
        "div",
        { staticClass: "row" },
        [
          _c("div", { staticClass: "col-12 col-sm-8" }, [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-body p-4" }, [
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("user-sidebar", {
                      attrs: { menu: "avatar", id: _vm.id }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-9 col-sm-9" },
                      [
                        _c("h4", { staticClass: "card-title" }, [
                          _vm._v(_vm._s(_vm.trans("user.avatar")))
                        ]),
                        _vm._v(" "),
                        _c("upload-image", {
                          attrs: {
                            id: "avatar",
                            "upload-path": "/user/profile/avatar/" + _vm.id,
                            "remove-path":
                              "/user/profile/avatar/remove/" + _vm.id,
                            "image-source": _vm.avatar.user
                          },
                          on: {
                            uploaded: _vm.updateAvatar,
                            removed: _vm.updateAvatar
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("user-summary", { attrs: { user: _vm.user } })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }